import { Link } from 'react-router-dom'
import './css/PageIndex.css'



let PageIndex = (props) => {

    return (
        <div className="PageIndex">
            <h2>Techstorm</h2>

            <p>Vi laver software løsninger i moderne teknologier</p>

            <p>Du kan også finde min blog <Link to="/blog">her</Link></p>

        </div>
    )

}

export default PageIndex