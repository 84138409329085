

/* 
TODO ->

  * What pages
  * An order of pages 
  * Some sort of slide show maybe do 
    * arrow key i.e. conventional slideshow
    * a scroll slide show
    * just make my own / copy -> https://bvaughn.github.io/react-presents/#/4/0
  * Figure out a cool background


  * 
*/


let CV = (props) => {


    return (
      <div>
          <h1>Welcome to my very professional programming CV </h1>

          <p>Please hire me *smile*</p>
      </div>
    )
}

export default CV