import React, { useEffect, useState, useRef } from 'react';

const style = {
  canvas: {
    display: "block"
  }
}

let normalRatio = 1920*1080
let configDots = 150
let calcScreenRatio = (w, h) => {
  return  normalRatio / (w*h)
}
let calculatedInitalDots = Math.floor(configDots / calcScreenRatio(window.innerWidth, window.innerHeight))

let DotHeaven = (props) => {
    const [windowWidth, SetWindowWidth] = useState(window.innerWidth);
    const [windowHeight, SetWindowHeight] = useState(window.innerHeight);
    const [screenRatio, SetscreenRatio] = useState(calcScreenRatio(window.innerWidth, window.innerHeight))
    const [settings, setSettings] = useState({
        background_color: {r: 28, g: 28, b: 28},
        nOfDots: calculatedInitalDots,
        speed: 0.05,
        borderSize: 50,
        lineRadius: 150,
        nOfTriesToFindSpot: 100,
        dotLifetime: 30000,
        opacity: props.opacity
    })
    const [dataDots, setDataDots] = useState(null)
    const [mousePos, setMousePos] = useState(null)
    const [mouseLastMove, setMouseLastMove] = useState(null)
    const canvasRef = useRef(null)


    useEffect(() => {

      let re = (e) => {
        SetWindowHeight(e.target.innerHeight)
        SetWindowWidth(e.target.innerWidth)
        SetscreenRatio(prevRatio => {

          if (prevRatio === calcScreenRatio(e.target.innerWidth, e.target.innerHeight))
          {
            return prevRatio;
          }
          else
          {
            const newRatio = calcScreenRatio(e.target.innerWidth, e.target.innerHeight);
            setSettings(prevSettings => {
              
              const newDots = Math.floor(configDots / newRatio);
              if (newDots !== prevSettings.nOfDots) {
                return {...prevSettings, nOfDots: newDots};
              }
              return prevSettings;
            })
  
            return newRatio;
          }

        });

      }



      if(props.enabled)
      {
        if(settings.nOfDots !== configDots)
        {
          //setSettings({...settings, ...{nOfDots: configDots}})
        }

        window.addEventListener("resize", re)
      }
      else {
        window.removeEventListener('resize', re)
        if(settings.nOfDots !== configDots)
        {
          setSettings({...settings, ...{nOfDots: configDots}})
        }
        if(windowHeight !== window.screen.height)
        {
          SetWindowHeight(window.screen.height)
        }
        if(windowWidth !== window.screen.width)
        {
          SetWindowWidth(window.screen.width)
        }

      }
      function handleMouseMove(e) {
        // limit the number of events per second
        if (Date.now() - mouseLastMove < 100 / 60) {
          return;
        }
        else
        {
          setMouseLastMove(Date.now())
          setMousePos({x: e.clientX, y: e.clientY})
        }
      }
      window.addEventListener('mousemove', handleMouseMove)



      return () => {
        window.removeEventListener('resize', re)
        window.removeEventListener('mousemove', handleMouseMove)
      }
    }, [
      props.enabled, 
      settings, setSettings,
      windowWidth, SetWindowWidth, 
      windowHeight, SetWindowHeight,
      mousePos, setMousePos,
      mouseLastMove, setMouseLastMove,
      screenRatio, SetscreenRatio
    ])


    

    let map_range = (value, low1, high1, low2, high2) =>
    {
      return low2 + (high2 - low2) * (value - low1) / (high1 - low1);
    }



    useEffect(() => {
      let canvas = canvasRef.current
      if(canvas !== null)
      {
        const ctx = canvas.getContext("2d")


        const background = (ctx, r,g,b) => {
          let color = rgbToHex(r,g,b)
    
          ctx.fillStyle  = color
          ctx.fillRect(0, 0, windowWidth, windowHeight)
        }
    
        const rgbToHex = (r,g,b) => {
          let rHex = r.toString(16);
          let gHex = g.toString(16);
          let bHex = b.toString(16);
    
          if(rHex === "0")
          {
            rHex = "00"
          }
          if(gHex === "0")
          {
            gHex = "00"
          }
          if(bHex === "0")
          {
            bHex = "00"
          }
    
          let string = "#" + rHex + gHex + bHex
          return string
        }

        let generateDot = (w, h) => {
          let randomX = Math.random()*100;
          let randomY = Math.random()*100;
    
          let x = map_range(randomX, 0, 100, 0, w)
          let y = map_range(randomY, 0, 100, 0, h)
    
          let randomVX = Math.random()*100;
          let randomVY = Math.random()*100;
    
          let vx = (map_range(randomVX, 0, 100, -w, w) / 1000) * settings.speed
          let vy = (map_range(randomVY, 0, 100, -h, h) / 1000) * settings.speed
    
          let vector = {x: vx, y: vy}
    
          return {x, y, vector}
        }
    
    
        let checkIfValidDot = (arr, newDot, numberOfTries) => {
          let shouldAdd = true;
          if(numberOfTries >= settings.nOfTriesToFindSpot)
          {
            return true;
          }
          if(arr.length > 1)
          {
            arr.forEach(dot => {
    
              let a = dot.x - newDot.x;
              let b = dot.y - newDot.y;
              let c = Math.sqrt( a*a + b*b );
    
              if(c < settings.lineRadius)
              {
                shouldAdd = false;
              }
              return shouldAdd
            })
          }
          return shouldAdd;
        }


        let makeValidDot = (dots, w, h, numberOfTries) => {
          let newDot = generateDot(w, h)
          let valid = checkIfValidDot(dots, newDot, numberOfTries)
    
          if(valid)
          {
            return newDot
          }
          else
          {
            return makeValidDot(dots, w, h, numberOfTries+1)
          }
    
        }

        let generateDots = (numberOfDots, w, h, init) => {
          let dots = []
          for(var i = 0; i < numberOfDots; i++)
          {
            let newDot;
            if(init)
            {
              newDot = makeValidDot(dots, w, h, 0)
    
            }
            else
            {
              newDot = makeValidDot(dots, w, h, 0)
              
              
    
              let left = Math.round(Math.random()*10) >= 5;
    
              let randomX = Math.random()*100;
              let randomY = Math.random()*100;
    
              let x;
              let y;
    
              if(left)
              {
                x = map_range(randomX, 0, 100, w, w + settings.borderSize)
                y = map_range(randomY, 0, 100, 0, h)
              }
              else
              {
                x = map_range(randomX, 0, 100, 0, 0 - settings.borderSize)
                y = map_range(randomY, 0, 100, 0, h)
              }
    
              let randomVX = Math.random()*100;
              let randomVY = Math.random()*100;
    
              let vx = (map_range(randomVX, 0, 100, -w, w) / 1000) * settings.speed
              let vy = (map_range(randomVY, 0, 100, -h, h) / 1000) * settings.speed

              if(left)
              {
                if (vx > 0)
                {
                  vx = -vx
                }
              }
              else
              {
                if (vx < 0)
                {
                  vx = -vx
                }
              }


    
              let vector = {x: vx, y: vy}
              newDot = {x, y, vector}
              
            }
    
            newDot = Object.assign(newDot, {born: Date.now() + (Math.random() * settings.dotLifetime)});
    
            dots.push(newDot)
          }
    
          return dots
        }

        let killDots = (dots, n) => {
          let localDots = dots

          for(var i = 0; i < Math.abs(n); i++)
          {
            let randomIndex = Math.floor(Math.random()*localDots.length)
            localDots.splice(randomIndex, 1)
          }
          return localDots
        }



        const update = (dots) => {
          let outOfBoundry = (dot) => {
            if(dot.x > (windowWidth + settings.borderSize))
            {
              return true
            }
            else if ( dot.x < (0 - settings.borderSize))
            {
              return true
            }
            else if (dot.y > (windowHeight + settings.borderSize))
            {
              return true
            }
            else if (dot.y < (0 - settings.borderSize))
            {
              return true
            }
            else
            {
              return false
            }
          }


          const gridCellSize = settings.lineRadius; 

          // Create a 2D array (the grid)
          let grid = [];
          for (let i = 0; i < canvas.width / gridCellSize; i++) {
            grid[i] = [];
            for (let j = 0; j < canvas.height / gridCellSize; j++) {
              grid[i][j] = [];
            }
          }

          // Populate the grid with the dots
          dots.forEach(dot => {
            let gridX = Math.floor(dot.x / gridCellSize);
            let gridY = Math.floor(dot.y / gridCellSize);
            if (gridX >= 0 && gridX < grid.length && gridY >= 0 && gridY < grid[0].length) {
              grid[gridX][gridY].push(dot);
            }
          });



          let newDots = dots.map(dot => {

            let newX = dot.x + dot.vector.x
            let newY = dot.y + dot.vector.y




            let connectedDots = [];

            // only calculate distance function on dots within the same grid location
            // results in fewer distance calculations. dont need to calculate dots distance when they are really far from each other
            // 
            let gridX = Math.floor(dot.x / gridCellSize);
            let gridY = Math.floor(dot.y / gridCellSize);
            if (gridX >= 0 && gridX < grid.length && gridY >= 0 && gridY < grid[0].length) {
          
              for (let i = Math.max(0, gridX - 1); i <= Math.min(grid.length - 1, gridX + 1); i++) {
                for (let j = Math.max(0, gridY - 1); j <= Math.min(grid[0].length - 1, gridY + 1); j++) {
                  grid[i][j].forEach(dot2 => {
              let a = dot.x - dot2.x;
              let b = dot.y - dot2.y;
                    let c = Math.sqrt(a * a + b * b);
                    if (c < settings.lineRadius) {
                      connectedDots.push({
                  x: dot2.x, 
                  y: dot2.y,
                  distance: c
                      });
                    }
                  });
                }
              }
            }

            let updatedDot = {x: newX, y: newY, vector: dot.vector, born: dot.born, connectedDots}



            return updatedDot
          })
          .filter(e => !outOfBoundry(e))
          .filter((dot, i) => {
            let age = Date.now() - dot.born
            if(age < settings.dotLifetime)
            {
              return dot
            }
            return dot
          })


          if(newDots.length !== settings.nOfDots)
          {
            let dif = settings.nOfDots - newDots.length;

            let newGenDots
            if (dif > 0)
            {
              newGenDots = generateDots(dif, windowWidth, windowHeight, false)
            newDots = newDots.concat(newGenDots)
            }
            else
            {
              newDots = killDots(newDots, dif)
            }

            
          }


          return newDots
        }


        const render = (dots) => {
          if(dots === 0) return;

            dots.forEach(dot => {

            if(typeof dot.connectedDots !== "undefined")
            {
              dot.connectedDots.forEach(dot2 => {
                if(dot2.distance < settings.lineRadius)
                {
                  let lineColor = map_range(dot2.distance, 20, settings.lineRadius, 255, settings.background_color.r)
                  ctx.strokeStyle = `rgb(${lineColor}, ${lineColor}, ${lineColor})`;
                  ctx.beginPath();
                  ctx.lineWidth = 0.7;
                  ctx.moveTo(dot.x, dot.y);
                  ctx.lineTo(dot2.x, dot2.y);
                  ctx.stroke();
                  ctx.lineWidth = 1;
                }
              })
            }

            // Mouse lines
            if(mousePos)
            {
              let a = mousePos.x - dot.x;
              let b = mousePos.y - dot.y;
              let c = Math.sqrt( a*a + b*b );
              let localMouseRange = settings.lineRadius * 1.5
              if(c < localMouseRange)
              {
                let lineColor = map_range(c, 20, localMouseRange, 255, settings.background_color.r)
                ctx.strokeStyle = `rgb(${lineColor}, ${lineColor}, ${lineColor})`;
                ctx.beginPath();
                ctx.lineWidth = 0.7;
                ctx.moveTo(dot.x, dot.y);
                ctx.lineTo(mousePos.x, mousePos.y);
                ctx.stroke();
                ctx.lineWidth = 1;
              }
            }
          })

          // Actual Dots
          //
          // Needs to be after the lines
          // you could draw the line then the dot
          // The problem is the incoming line from the other dots will be drawn over the dot
            dots.forEach(dot => {


            let AmountOfConnectedDots = (typeof dot.connectedDots !== 'undefined') ? dot.connectedDots.length : 0
            let dotSize = map_range(AmountOfConnectedDots, 0, 6, 0, 2)

              ctx.beginPath();
              ctx.arc(dot.x, dot.y, dotSize, 0, 2 * Math.PI, true);
              ctx.fillStyle = "white";
              ctx.fill();
              ctx.strokeStyle = "rgba(1, 1, 1, 0)";
              ctx.stroke();

            })

        
        }

        let requestId;

        let dots;
        if(dataDots)
        {
          dots = dataDots
        }
        else
        {
          dots = generateDots(settings.nOfDots, windowWidth, windowHeight, true)
        }

        const frame = (e) => {
          let color = settings.background_color;
          background(ctx, color.r, color.g, color.b)
          //requestId = undefined;

          dots = update(dots)
          render(dots)
          setDataDots(dots)

          start()
        }

        function start()
        {
          if (!requestId)
          {
             requestId = window.requestAnimationFrame(frame);
          }
        }

        function stop() {
          if (requestId) {
             window.cancelAnimationFrame(requestId);
             requestId = undefined;
          }
        }

        if(props.enabled)
        {
          start()
        }
        else {
          let color = settings.background_color;
          background(ctx, color.r, color.g, color.b)
          dots = update(dots)
          render(dots)
        }


        return () => {
          //setDataDots(dots)
          //props.data.setDots(dots)
          stop()
        }
      }
    }, [setDataDots, dataDots, props.enabled, settings, windowHeight, windowWidth, mousePos])


    

    return (
        <div className="background" style={{opacity: settings.opacity, position: "fixed", top: 0, left: 0, zIndex: -1}}>
          <canvas ref={canvasRef} style={style.canvas} width={windowWidth} height={windowHeight} />

        </div>
    );
}

export default DotHeaven
