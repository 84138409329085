import { Routes, Route } from "react-router-dom";

import './css/MainLayout.css';
import DotHeaven from '../frontpage/DotHeaven/DotHeaven'
import Nav from '../comps/Nav'
import PageOm from './PageOm'
import PageBlog from './PageBlog'
import PageProjekter from './PageProjekter'
import PageIndex from './PageIndex'

let MainLayout = (props) => {

    return (
        
        <div>
            <div className="MainLayout">
            <Nav />


            <DotHeaven enabled={true} opacity="85%"/>
            <div className="bg"></div>
            
            <div className="gridWrapper">
                <div className="contentBox">

                    <Routes> 
                        <Route path="/" element={<PageIndex />} />
                        <Route path="projekter" element={<PageProjekter />} />
                        <Route path="blog" element={<PageBlog />} />
                        <Route path="om" element={<PageOm />} />
                    </Routes>

                </div>
            </div>

            </div>
        </div>

    )
}

export default MainLayout