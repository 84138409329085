import { Routes, Route } from "react-router-dom";

import './App.css';
import MainLayout from './pages/MainLayout'
import CV from './pages/CV'


let App = (props) => {
  return (
    <div className="App">
      <Routes>
        <Route path="*" element={<MainLayout />} />
        <Route path="cv" element={<CV />} />
      </Routes>
    </div>
  );
}

export default App;
