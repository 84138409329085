import MarkdownViewer from "../comps/MarkdownViewer"
let PageBlog = (props) => {
    const markdownContent = `
        # Markdown Example

        This is an example of Markdown content rendered using ReactMarkdown.

        - **Bold Text**
        - *Italic Text*
        - [Link](https://example.com)
        - \`Inline Code\`

        ## Subheading

        1. Numbered List Item
        2. Another Numbered List Item

        ### Code Block

        \`\`\`javascript
        function greet(name) {
        return 'Hello, ' + name + '!';
        }

        console.log(greet('World'));
        \`\`\`
        `;

        //<MarkdownViewer markdownContent={markdownContent}/>
    return (
        <div className="PageBlog">
            <h2>Dev Blog</h2>
        </div>
    )

}

export default PageBlog