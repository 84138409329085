let PageOm = (props) => {

    return (
        <div className="PageOm">
            <h2>Om os</h2>
        </div>
    )

}

export default PageOm