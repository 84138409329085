let PageCases = (props) => {

    return (
        <div className="PageCases">
            <h2>Projekter</h2>
            <p>Techstorm har lavet forskellige projekter igennem tiden her er et udpluk</p>

            <br />

            <h4>Albertslund Kommune - Chromebook reparations system</h4>
            <p>Vi har lavet et system til Albertslund Kommune der skulle håndterer reparationer af Chromebooks</p>

        </div>
    )

}

export default PageCases