import { Link } from "react-router-dom";

import './Nav.css';

let Nav = (props) => {

    return (
        <div className="navBox">
          <nav className="nav">
            <Link to="/">Home</Link> {" "}
            <Link to="/projekter">Projekter</Link> {" "}
            <Link to="/blog">Blog</Link> {" "}
            <Link to="/om">Om os</Link>
          </nav>
        </div>
    )

}

export default Nav